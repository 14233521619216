@charset "utf-8";

$black: #000;
$grey: #aaaaaa;

// COLORS
$theme-colors: (
  // "primary": #ed1c25,
  "primary": #000,
  "secondary": #fef8f9,
  "red": #FF2079,
  "blue": #440BD4,
  "purple": #E92EFB,
  "darkblue": #04005E,
  "success": #28a745,
  "danger": #ff4136,
  "warning": #ffc107,
  "info": #17a2b8,
  "light": #f8f9fa,
  "dark": #04005E,
  "white": #ffffff,
  "transparent": transparent,
  "black": #000,
);

$input-font-size: 24px;
$input-border-radius: 8px;
$input-font-weight: 600;
$input-border-color: $black;
$input-placeholder-color: $grey;


// TEXT
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Savage';
  src: local('Savage'), url(../fonts/Savage.otf) format('otf');
  src: url("../fonts/Savage.eot");
  src: url("../fonts/Savage.woff") format("woff"),
  url("../fonts/Savage.svg#filename") format("svg");
}


$font-family-savage: 'Savage', sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-base: $font-family-montserrat;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    // margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

.header-font {
  h1 {
    // font-family: $font-family-savage;
    font-family: $font-family-montserrat;
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  .header-font {
    h1 {
      font-size: 55px;
    }
  }
  
  .header-font.above-fold-header {
    h1 {
      // font-size: 90px;
      font-size: 60px;
    }
  }
}
